import React from 'react';
import Layout from '../components/Layout';

import Dibujo3d from '../../static/images/solutions/linea_de_lavado/c3.png'

const Service_one = ({location}) => {
    const {state = {}} = location;
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/solutions/linea_de_lavado/3.jpg)`,
                        }}
                    >
                        <h1>Línea de lavado de plástico HDPE / LDP</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Antecedentes</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Línea de lavado y reciclaje de HDPE de alta calidad
                                <br/>
                                La línea de lavado y reciclaje de alta calidad para diversos plásticos rígidos es
                                adecuada para plásticos rígidos producidos en el consumo de la vida diaria
                                (especialmente para botellas de leche de HDPE / canastas pequeños de HDPE azul con alto
                                valor de reciclaje, y puede manejar plásticos rígidos misceláneos diarios, incluso
                                maceta de aceite de motor de 2.5 litros, esta línea de limpieza puede mejorar
                                efectivamente la calidad del producto y pertenece a la serie media y alta de líneas de
                                limpieza de plástico rígido y misceláneo) para reciclaje, limpieza y reutilización;
                                <br/>
                                La capacidad del procesamiento del sistema: 1500-3000 kg / h
                                <br/>
                                <br/>
                                Línea de limpieza de reciclaje de maceta de aceite de PE
                                <br/>
                                a línea de lavado y reciclaje de alta calidad para diversos plásticos rígidos es
                                adecuada para plásticos rígidos producidos en el consumo de la vida diaria
                                (especialmente para botellas de leche de HDPE / canastas pequeños de HDPE azul con alto
                                valor de reciclaje, y puede manejar plásticos rígidos misceláneos diarios, incluso
                                maceta de aceite de motor de 2.5 litros, esta línea de limpieza puede mejorar
                                efectivamente la calidad del producto y pertenece a la serie media y alta de líneas de
                                limpieza de plástico rígido y misceláneo) para reciclaje, limpieza y reutilización;
                                <br/>
                                La capacidad del procesamiento del sistema: 500-1000 kg / h
                                <br/>
                                <br/>
                                Sistema de reciclaje de plásticos rígidos （PE 、 PP 、 LDPE 、 HDPE 、 PO 、 PS 、 EPS 、 ABS 、
                                PA 、 PC 、 PVC
                                <br/>
                                Zion Tech ofrece línea de lavado de plástico de desecho rígido que se utiliza
                                principalmente para reciclar productos plásticos utilizados en la vida diaria. A través
                                de la clasificación, trituración y lavado, se obtienen materiales limpios y
                                reutilizables para uso posterior para un circuito cerrado de plásticos duros. Las
                                materias primas que puede procesar el sistema incluyen:
                                <br/>
                                - - PE 、 PP 、 LDPE 、 HDPE 、 PO 、 PS 、 EPS 、 ABS 、 PA 、 PC 、 PVC
                                <br/>
                                Los productos comúnmente vistos, hechos de los materiales anteriores, son de formas
                                huecos que son de consumo, juguetes de plástico, pequeños artículos para el hogar de
                                plástico, tinas de plástico azul, etc.
                                <br/>
                                La capacidad del sistema: 1500 kg / h ~ 3000 kg / h
                                <br/>
                                <br/>
                                Línea de desmantelamiento, reciclaje y limpieza de electrodomésticos de plástico PC \
                                ABS
                                <br/>
                                La línea de reciclaje de desmantelamiento y limpieza de electrodomésticos PC \ ABS es
                                adecuada para el reciclaje y limpieza de carcasas de plástico PC \ ABS después de
                                desmantelar electrodomésticos. El funcionamiento de esta línea de limpieza puede mejorar
                                efectivamente la calidad de los productos plásticos finales. Esto pertenece a la línea
                                de limpieza de reciclaje de plásticos rígidos diversos, cuyo proceso conlleva unas
                                series de nivel media y alta de reciclaje, limpieza y reutilización de la misma.
                                <br/>
                                La capacidad de procesamiento de la línea de desmantelamiento, limpieza y reciclaje de
                                electrodomésticos PC \ ABS es de 1500-3000 kg / h
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Característica</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                <ul>
                                    <li>
                                        El sistema de reciclaje de plástico rígido
                                        Zion Tech puede procesar una amplia variedad de plásticos, con un diseño de
                                        proceso probado a lo largo de los años. Con un módulo de lavado químico
                                        caliente, la línea de lavado puede lograr una calidad mejor y más consistente
                                        para el producto final.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Toda la línea de lavado se puede equipar con
                                        un sistema DCS (control inteligente) y control PLC para minimizar el uso de mano
                                        de obra.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        La línea de lavado también está diseñada con
                                        un sistema de circulación de agua química y un sistema de circulación de agua
                                        de enjuague en línea para reducir la huella de carbono.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        La línea de lavado cuenta con un diseño de arranque y parada con
                                        un solo botón que facilita mucho la operación y el mantenimiento.
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Capacidad de entrada</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                1500-9000 kg / h
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Opciones de modelo</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">

                            <img src={Dibujo3d} style={{width: "100%"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
